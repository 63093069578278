import i18n from '@/i18n'
import { api } from '@/boot/axios'

export const routes = [
  {
    path: '/',
    redirect: `/${i18n.locale}`,
  },
  {
    path: '/:lang',
    component: {
      render(c) {
        return c('router-view')
      },
    },
    meta: {
      title: 'About Page - Example App',
    },
    children: [
      {
        path: 'account',
        component: () => import('../views/account/AdminPage.vue'),
        beforeEnter: (to, from, next) => {
          //to, from, next
          const roles = ['tour-operator-entity', 'tour-operator-individual']

          api
            .get('user')
            .then((response) => {
              if (
                response.data &&
                roles.includes(response.data.data.roles.slug)
              ) {
                next()
              } else {
                window.location = '/' + to.params.lang + '/login'
              }
            })
            .catch((error) => {
              if (error.response.status == 401) {
                window.location = '/' + to.params.lang + '/login'
                localStorage.removeItem('token')
              }
            })
        },
        children: [
          {
            path: '/',
            name: 'Account',
            component: () => import('../views/account/Account.vue'),
          },
          {
            path: 'edit',
            name: 'AccountEdit',
            component: () => import('../views/account/AccountEdit.vue'),
          },
          {
            path: 'applications/foreign-tourist/create',
            name: 'AddTourist',
            component: () =>
              import(
                '../views/account/applications/foreign-tourist/Create.vue'
              ),
          },
          {
            path: 'applications/foreign-tourist/:application_id',
            name: 'AddTourist',
            component: () =>
              import('../views/account/applications/foreign-tourist/Show.vue'),
          },
          {
            path: 'applications/kids-go-free/create',
            name: 'KidsGoFreeCreate',
            meta: {
              pageType: 'create'
            },
            component: () =>
              import('../views/account/applications/kids-go-free/KidsGoFree.vue'),
          },
          {
            path: 'applications/kids-go-free/:application_id',
            name: 'KidsGoFreeView',
            meta: {
              pageType: 'view'
            },
            component: () =>
              import('../views/account/applications/kids-go-free/KidsGoFree.vue'),
          },
          {
            path: 'applications/kids-go-free/:application_id/edit',
            name: 'KidsGoFreeEdit',
            meta: {
              pageType: 'edit'
            },
            component: () =>
              import('../views/account/applications/kids-go-free/KidsGoFree.vue'),
          },
          {
            path: 'subsiding/foreign-tourist',
            name: 'Foreign',
            component: () => import('../views/account/SubsidingForeign.vue'),
          },
          {
            path: 'subsiding/kids-go-free',
            name: 'Kids',
            component: () => import('../views/account/SubsidingKids.vue'),
          },
        ],
      },
      {
        path: 'administration',
        component: () => import('../views/administrator/AdminPage.vue'),
        beforeEnter: (to, from, next) => {
          const roles = [
            'administrator',
            'executor',
            'stationery',
            'accountant',
          ]

          api
            .get('user')
            .then((response) => {
              if (
                response.data &&
                roles.includes(response.data.data.roles.slug)
              ) {
                next()
              } else {
                window.location = '/' + to.params.lang + '/login'
              }
            })
            .catch((error) => {
              if (error.response.status == 401) {
                window.location = '/' + to.params.lang + '/login'
                localStorage.removeItem('token')
              }
            })
        },
        children: [
          {
            path: '/',
            name: 'Account',
            component: () => import('../views/administrator/Account.vue'),
          },
          /*{
						path: 'applications/foreign-tourist/:application1_id',
						name: 'AddTourist',
						component: () => import('../views/administration/ViewsTouristedit.vue'),
					},*/ {
            path: 'kids-views/:id',
            name: 'AddTourist',
            component: () => import('../views/administrator/ViewsKids.vue'),
          },
          {
            path: 'administrator',
            name: 'Account',
            component: () =>
              import('../views/administrator/AdministratorAccount.vue'),
          },
          {
            path: 'applications/foreign-tourist/:application_id/edit',
            name: 'AddTourist',
            component: () =>
              import('../views/administration/foreign-tourist/Edit.vue'),
          },
          {
            path: 'executor',
            name: 'Account',
            component: () =>
              import('../views/administrator/ExecutorAccount.vue'),
          },
          {
            path: 'executor/tourist-views/:id',
            name: 'AddTourist',
            component: () =>
              import('../views/administrator/ExecutorViewsTourist.vue'),
          },
          {
            path: 'finance',
            name: 'Account',
            component: () =>
              import('../views/administrator/FinanceAccount.vue'),
          },
          {
            path: 'finance/tourist-views/:id',
            name: 'AddTourist',
            component: () =>
              import('../views/administrator/FinanceViewsTourist.vue'),
          },
          {
            path: 'registrations',
            name: 'Registrations',
            component: () => import('../views/administrator/Registration.vue'),
          },
          {
            path: 'applications/kids-go-free/create',
            name: 'administration.applications.kids-go-free.create',
            component: () =>
              import('../views/administration/kids-go-free/create.vue'),
          },
          {
            path: 'applications/kids-go-free/:application_id/edit',
            name: 'administration.applications.kids-go-free.edit',
            component: () =>
              import('../views/administration/kids-go-free/edit.vue'),
          },
          {
            path: 'tour-operators',
            name: 'administration.tour-operators.index',
            component: () =>
              import('../views/administration/tour-operators/Index.vue'),
          } /*{
						path: 'tour-operators/create',
						name: 'administration.tour-operators.create',
						component: () => import('../views/administration/tour-operators/Create.vue')
					}*/,
        ],
      },
      {
        path: '',
        component: () => import('../views/Index.vue'),
        children: [
          {
            path: '/',
            name: 'Home',
            component: () => import('../views/Main.vue'),
          },
          {
            path: 'about',
            name: 'About',
            component: () => import('../views/About.vue'),
          },
          {
            path: 'subsiding/foreign-tourist',
            name: 'Foreign',
            component: () => import('../views/SubsidingForeign.vue'),
          },
          {
            path: 'subsiding/kids-go-free',
            name: 'Kids',
            component: () => import('../views/SubsidingKids.vue'),
          },
          {
            path: 'login',
            name: 'Auth',
            component: () => import('../views/Auth.vue'),
          },
          {
            path: 'registration',
            name: 'Registration',
            component: () => import('../views/Registration.vue'),
          },
          {
            path: '*',
            component: () => import('../views/NotFound.vue'),
          },
        ],
      },
    ],
  },
]
