import { api } from '../boot/axios'

export default {
  state: () => ({
    countries: [],
  }),
  mutations: {
    SET_COUNTRIES(state, payload) {
      state.countries = payload
    },
  },
  actions: {
    async _setCountries({ commit, state }) {
      if (state.countries.length) return

      api.get('/countries').then((response) => {
        if (response.data && response.data.data) {
          commit('SET_COUNTRIES', response.data.data)
        }
      })
    },
  },
  getters: {
    _getCountries(state) {
      return state.countries
    },
  },
}
