import Vue from 'vue'
import VueRouter from 'vue-router'
import VueI18n from 'vue-i18n'
import { routes } from './routes'
import VueHead from 'vue-head'
import i18n from '../i18n'
Vue.use(VueI18n)

Vue.use(
  VueHead,
  // 	, {
  // 	separator: "|",
  // 	complement:
  // 		i18n.locale == "kz"
  // 			? "«eQonaq» мамандандырылған ақпараттық жүйесі"
  // 			: i18n.locale == "ru"
  // 			? "Специализированная информационная система «eQonaq»"
  // 			: 'Specialized information system "eQonaq"'
  // }
)
Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})
export default router
