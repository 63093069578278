<template>
  <footer>
    <div class="container">
      <div class="footer__row">
        <div class="footer__left">
          <div class="footer__left--left">
            <div class="footer__logo">
              <a href="https://kazakhstan.travel/">
                <img src="../assets/img/kt/logo.svg" alt="" />
              </a>
            </div>
            <div class="footer__feedback">
              <a href="https://kazakhstan.travel/feedback">{{
                $t('footer.contact us')
              }}</a>
            </div>

            <div class="footer__phones">
              <div class="footer__phones--title">
                {{ $t('footer.phones for information') }}:
              </div>
              <div class="footer__phone--list">
                <p>
                  {{ $t('footer.for media') }}:
                  <strong
                    ><a href="tel:8 701 370 6227">8 701 370 6227</a></strong
                  >
                  {{ $t('footer.for media person') }}
                </p>
                <p>
                  {{ $t('footer.for tour operators') }}:
                  <strong
                    ><a href="tel:8 776 113 2995">8 776 113 2995</a></strong
                  >
                  {{ $t('footer.for tour operators person') }}
                </p>
              </div>
            </div>

            <div class="footer-socials">
              <a
                href="https://www.facebook.com/TraveltoKazakhstan/"
                target="_blank"
                ><img src="../assets/img/kt/icon-facebook.svg" alt=""
              /></a>
              <a href="https://twitter.com/qazaqstantravel" target="_blank"
                ><img src="../assets/img/kt/icon-twitter.svg" alt=""
              /></a>
              <a
                href="https://www.instagram.com/travel.kazakhstan/"
                target="_blank"
                ><img src="../assets/img/kt/icon-instagram.svg" alt=""
              /></a>
              <a href="https://youtube.com/Kazakhstantravel" target="_blank"
                ><img src="../assets/img/kt/icon-youtube.svg" alt=""
              /></a>
            </div>
          </div>

          <div class="footer__left--right">
            <div class="footer__left--list">
              <nav class="footer-menu">
                <a
                  class="footer-menu-link"
                  href="https://kazakhstan.travel/business"
                  >{{ $t('footer.for business') }}</a
                >
                <a
                  class="footer-menu-link"
                  href="https://kazakhstan.travel/tourist-help/en/resources"
                  >{{ $t('footer.list of useful web resources') }}</a
                >
                <a
                  class="footer-menu-link"
                  href="https://kazakhstan.travel/partners"
                  >{{ $t('footer.partners') }}</a
                >
              </nav>
              <nav class="footer-menu">
                <a
                  class="footer-menu-link"
                  href="https://kazakhstan.travel/privacy"
                  >{{ $t('footer.agreement') }}</a
                >
                <a
                  class="footer-menu-link"
                  href="https://kazakhstan.travel/about"
                  >{{ $t('footer.about the project') }}</a
                >
                <a
                  class="footer-menu-link"
                  href="https://kazakhstan.travel/contacts"
                  >{{ $t('footer.contacts') }}</a
                >
                <a
                  class="footer-menu-link"
                  href="https://kazakhstan.travel/feedback"
                  >{{ $t('footer.feedback') }}</a
                >
              </nav>
            </div>
          </div>
        </div>
        <div class="footer__right">
          <div class="footer-block">
            <a
              class="footer-menu-logo-link"
              target="_blank"
              href="https://www.gov.kz/memleket/entities/mcs?lang=en"
            >
              <div class="footer-logo footer-logo--small">
                <img src="../assets/img/kt/MCS_Logo.png" alt="" />
              </div>
              <div class="footer-text" style="font-size: 14px">
                {{
                  $t(
                    'footer.ministry of culture and sports of the republic of kazakhstan',
                  )
                }}
              </div>
            </a>
          </div>
          <div class="footer-block">
            <a
              class="footer-menu-logo-link"
              href="http://qaztourism.kz/"
              target="_blank"
            >
              <div class="footer-logo footer-logo--small">
                <img src="../assets/img/kt/logo-kt-full.png" alt="" />
              </div>
              <div class="footer-text" style="font-size: 14px">
                {{
                  $t(
                    'footer.joint stock Company national company kazakh tourism',
                  )
                }}
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
}
</script>

<style scoped></style>
