import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import router from './router'
import store from './store/index'

// import VueI18n from "vue-i18n";
import i18n from './i18n'
import vuetify from './plugins/vuetify'
import vmodal from 'vue-js-modal'
import VTooltipPlugin from 'v-tooltip'
import Notifications from 'vue-notification'
import Toast from "vue-toastification"
import { VueMaskDirective } from 'v-mask'
import "vue-toastification/dist/index.css"

import '@fancyapps/ui'
import '@fancyapps/ui/dist/fancybox.css'

Vue.use(VTooltipPlugin)
Vue.use(vmodal)
Vue.use(Notifications)
Vue.use(Vuex)
Vue.directive('mask', VueMaskDirective);
Vue.use(Toast, {})

Vue.config.productionTip = false

Vue.component('v-header', require('./components/Header.vue').default)
Vue.component(
  'v-mobile-header',
  require('./components/HeaderMobile.vue').default,
)
Vue.component('v-footer', require('./components/Footer.vue').default)

Vue.component(
  'v-account-header',
  require('./components/account/Header.vue').default,
)
Vue.component(
  'v-admin-header',
  require('./components/admin/Header.vue').default,
)

router.beforeEach((to, from, next) => {
  // use the language from the routing param or default language

  let locale = to.params.lang
  let locales = ['ru', 'kz', 'en']

  if (!locales.includes(locale)) {
    window.location = `/ru/404`
  }

  if (!locale) {
    locale = 'ru'
  }

  // set the current language for i18n.

  i18n.locale = locale

  next()
})

Vue.prototype.$VUE_BASE_URL = process.env.VUE_APP_BASE_URL;

const app = new Vue({
  router,
  i18n,
  vuetify,
  store: new Vuex.Store(store),
  render: (h) => h(App),
})

app.$mount('#app')
