import user from './user'
import countries from './countries'

const store = {
  modules: {
    user,
    countries,
  },
}

export default store
