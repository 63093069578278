<template>
  <div
    id="app"
    v-bind:class="{ 'home-page': classPage, mobile__active: mobileStatus }"
  >
    <router-view :key="$route.path" />
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      namePagePer: false,
      mobileStatus: false,
    }
  },
  computed: {
    classPage: function () {
      return this.namePageFunc()
    },
  },
  methods: {
    namePageFunc() {
      if (this.$route.name != 'Home') {
        return (this.namePagePer = false)
      } else {
        return (this.namePagePer = true)
      }
    },
    mobileActive(status) {
      this.mobileStatus = status
    },
  },
  watch: {},
}
</script>
