import { api } from '../boot/axios'

export default {
  state: () => ({
    user: null,
  }),
  mutations: {
    SET_USER(state, payload) {
      state.user = payload
    },
  },
  actions: {
    async _setUser({ commit, state }) {
      if (state.user) return

      api.get('/user').then((response) => {
        if (response.data && response.data.data) {
          if (
            response.data.data.payment_accounts &&
            response.data.data.payment_accounts.length > 0
          ) {
            commit('SET_USER', response.data.data)
          }
        }
      })
    },
  },
  getters: {
    _getUser(state) {
      return state.user
    },
    _getPaymentAccounts(state) {
      const payment_accounts =
        state.user && state.user.payment_accounts
          ? state.user.payment_accounts
          : []

      return payment_accounts.map((account) => {
        return {
          label: account.number,
          value: account.id,
        }
      })
    },
    _getCertificateOfDebts(state) {
      const certificate_of_debts =
        state.user && state.user.certificate_of_debts
          ? state.user.certificate_of_debts
          : []

      return certificate_of_debts.map((certificate) => {
        return {
          label: certificate.src.name,
          value: certificate.id,
        }
      })
    },
  },
}
