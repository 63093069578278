import Vue from 'vue'
import axios from 'axios'

Vue.prototype.$axios = axios

/*
	
if (localStorage.user) {
	const user = localStorage.user;
}

*/

const headers = {}

if (localStorage.token) {
  headers.authorization = localStorage.getItem('token')
}

if (localStorage.getItem('lang_locale')) {
  headers['Accept-Language'] = localStorage.getItem('lang_locale') || 'ru'
}


const api = axios.create({
  baseURL: 'https://backend.subsidizing.crocos.kz/api/v1',
  headers: headers,
})

//	Vue.prototype.$api = api

export { axios, api }
