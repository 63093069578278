<template>
  <header>
    <div class="container">
      <div class="header__row">
        <div class="header__left">
          <router-link :to="'/' + $i18n.locale + '/'">
            <img
              src="../assets/img/logo.svg"
              alt=""
              style="width: 85px; height: auto"
            />
          </router-link>
        </div>
        <div class="header__right">
          <div class="header__menu">
            <ul class="menu">
              <li>
                <a :href="'/' + $i18n.locale + '/'">{{ $t('menu.home') }}</a>
              </li>
              <!--              <li><a :href="'/'+$i18n.locale+'/about'">{{ $t('menu.about') }}</a></li>-->
              <li class="menu__item--children">
                <a
                  ><!--  :href="'/'+$i18n.locale +'/'" -->
                  {{ $t('menu.subsiding') }}
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5 7.5L10 12.5L15 7.5"
                      stroke="#0E1839"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </a>
                <ul class="menu--children">
                  <li>
                    <a
                      :href="
                        '/' +
                        $i18n.locale +
                        '/account/applications/kids-go-free/create'
                      "
                      >{{ $t('menu.kids') }}</a
                    >
                  </li>
                  <li>
                    <a
                      :href="'/' + $i18n.locale + '/subsiding/foreign-tourist'"
                      >{{ $t('menu.foreign') }}</a
                    >
                  </li>
                </ul>
              </li>
              <!--<li><a :href="'/'+$i18n.locale +'/'">{{ $t('menu.consultation') }}</a></li>-->
            </ul>
          </div>
          <div class="header__lang">
            <div class="header__lang--top">
              <div class="header__lang--text">
                {{ langOn }}
              </div>
              <div class="header__lang--arrow">
                <svg
                  width="10"
                  height="8"
                  viewBox="0 0 10 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 7.9779L0.669875 0.48456L9.33013 0.484561L5 7.9779Z"
                    fill="#FFCE03"
                  />
                </svg>
              </div>
            </div>
            <div class="header__lang--list">
              <ul>
                <li>
                  <a href="#" @click.prevent="setLocale('kz')">Қазақ</a>
                </li>
                <li>
                  <a href="#" @click.prevent="setLocale('ru')">Русский</a>
                </li>
                <li>
                  <a href="#" @click.prevent="setLocale('en')">English</a>
                </li>
              </ul>
            </div>
          </div>
          <div
            class="header__login"
            v-if="
              user &&
              ['tour-operator-entity', 'tour-operator-individual'].includes(
                user.role.slug,
              )
            "
          >
            <a
              :href="'/' + $i18n.locale + '/account'"
              class="header__login--top"
            >
              <div class="header__login--name">{{ $t('menu.sign') }}</div>
              <div
                class="header__login--icon"
                v-tooltip.bottom="{
                  content: $t('menu.sign'),
                  class: ['table__tooltip'],
                }"
              >
                <svg
                  width="41"
                  height="41"
                  viewBox="0 0 41 41"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style="float: left"
                >
                  <path
                    d="M35.1328 6.85028C31.3774 3.09853 26.3018 0.998291 20.9911 0.998291C15.6489 0.998291 10.6265 3.07683 6.84902 6.8509C3.07152 10.625 0.991211 15.643 0.991211 20.9805C0.991211 25.691 2.66285 30.2648 5.69871 33.86C6.05246 34.2785 6.42434 34.6834 6.8116 35.0723C7.08215 35.3439 7.53934 35.0988 7.46965 34.7219C7.31965 33.9106 7.24121 33.0745 7.24121 32.2205C7.24121 26.5201 10.7343 21.6197 15.6942 19.5432C15.9516 19.4354 16.018 19.0979 15.8161 18.9053C14.3254 17.4836 13.4184 15.4562 13.4958 13.2227C13.6311 9.32097 16.7904 6.15066 20.695 5.99963C24.9648 5.83438 28.4912 9.25712 28.4912 13.4872C28.4912 15.616 27.5979 17.5399 26.1663 18.9053C25.9644 19.0979 26.0309 19.4354 26.2882 19.5432C31.2482 21.6197 34.7412 26.5201 34.7412 32.2205C34.7412 33.0745 34.6628 33.9106 34.5127 34.7218C34.443 35.0987 34.9002 35.3439 35.1707 35.0722C35.5581 34.6833 35.9301 34.2782 36.284 33.8595C39.3196 30.2649 40.9912 25.691 40.9912 20.9805C40.9912 15.6427 38.9107 10.6244 35.1328 6.85028V6.85028Z"
                    fill="#0E1839"
                  />
                </svg>
              </div>
            </a>
          </div>
          <div
            class="header__login"
            v-else-if="
              user &&
              [
                'administrator',
                'executor',
                'stationery',
                'accountant',
              ].includes(user.role.slug)
            "
          >
            <a
              :href="'/' + $i18n.locale + '/administration'"
              class="header__login--top"
            >
              <div class="header__login--name">{{ $t('menu.sign') }}</div>
              <div
                class="header__login--icon"
                v-tooltip.bottom="{
                  content: $t('menu.sign'),
                  class: ['table__tooltip'],
                }"
              >
                <svg
                  width="41"
                  height="41"
                  viewBox="0 0 41 41"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style="float: left"
                >
                  <path
                    d="M35.1328 6.85028C31.3774 3.09853 26.3018 0.998291 20.9911 0.998291C15.6489 0.998291 10.6265 3.07683 6.84902 6.8509C3.07152 10.625 0.991211 15.643 0.991211 20.9805C0.991211 25.691 2.66285 30.2648 5.69871 33.86C6.05246 34.2785 6.42434 34.6834 6.8116 35.0723C7.08215 35.3439 7.53934 35.0988 7.46965 34.7219C7.31965 33.9106 7.24121 33.0745 7.24121 32.2205C7.24121 26.5201 10.7343 21.6197 15.6942 19.5432C15.9516 19.4354 16.018 19.0979 15.8161 18.9053C14.3254 17.4836 13.4184 15.4562 13.4958 13.2227C13.6311 9.32097 16.7904 6.15066 20.695 5.99963C24.9648 5.83438 28.4912 9.25712 28.4912 13.4872C28.4912 15.616 27.5979 17.5399 26.1663 18.9053C25.9644 19.0979 26.0309 19.4354 26.2882 19.5432C31.2482 21.6197 34.7412 26.5201 34.7412 32.2205C34.7412 33.0745 34.6628 33.9106 34.5127 34.7218C34.443 35.0987 34.9002 35.3439 35.1707 35.0722C35.5581 34.6833 35.9301 34.2782 36.284 33.8595C39.3196 30.2649 40.9912 25.691 40.9912 20.9805C40.9912 15.6427 38.9107 10.6244 35.1328 6.85028V6.85028Z"
                    fill="#0E1839"
                  />
                </svg>
              </div>
            </a>
          </div>
          <div class="header__login" v-else>
            <a :href="'/' + $i18n.locale + '/login'" class="header__login--top">
              <div class="header__login--name">{{ $t('menu.sign') }}</div>
              <div
                class="header__login--icon"
                v-tooltip.bottom="{
                  content: $t('menu.sign'),
                  class: ['table__tooltip'],
                }"
              >
                <svg
                  width="41"
                  height="41"
                  viewBox="0 0 41 41"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style="float: left"
                >
                  <path
                    d="M35.1328 6.85028C31.3774 3.09853 26.3018 0.998291 20.9911 0.998291C15.6489 0.998291 10.6265 3.07683 6.84902 6.8509C3.07152 10.625 0.991211 15.643 0.991211 20.9805C0.991211 25.691 2.66285 30.2648 5.69871 33.86C6.05246 34.2785 6.42434 34.6834 6.8116 35.0723C7.08215 35.3439 7.53934 35.0988 7.46965 34.7219C7.31965 33.9106 7.24121 33.0745 7.24121 32.2205C7.24121 26.5201 10.7343 21.6197 15.6942 19.5432C15.9516 19.4354 16.018 19.0979 15.8161 18.9053C14.3254 17.4836 13.4184 15.4562 13.4958 13.2227C13.6311 9.32097 16.7904 6.15066 20.695 5.99963C24.9648 5.83438 28.4912 9.25712 28.4912 13.4872C28.4912 15.616 27.5979 17.5399 26.1663 18.9053C25.9644 19.0979 26.0309 19.4354 26.2882 19.5432C31.2482 21.6197 34.7412 26.5201 34.7412 32.2205C34.7412 33.0745 34.6628 33.9106 34.5127 34.7218C34.443 35.0987 34.9002 35.3439 35.1707 35.0722C35.5581 34.6833 35.9301 34.2782 36.284 33.8595C39.3196 30.2649 40.9912 25.691 40.9912 20.9805C40.9912 15.6427 38.9107 10.6244 35.1328 6.85028V6.85028Z"
                    fill="#0E1839"
                  />
                </svg>
              </div>
            </a>
          </div>
          <div class="header__burger">
            <div class="header__burger--click" @click="mobileActive">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3 6H21"
                  stroke="#0E1839"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M3 12H21"
                  stroke="#0E1839"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M3 18H21"
                  stroke="#0E1839"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'Header',
  data: function () {
    return {
      mobileClick: false,
      user: localStorage.user ? JSON.parse(localStorage.user) : null,
    }
  },
  computed: {
    langOn: function () {
      var lang = this.$i18n.locale
      if (lang == 'ru') {
        lang = 'Русский'
      } else if (lang == 'kz') {
        lang = 'Қазақ'
      } else if (lang == 'en') {
        lang = 'English'
      }
      return lang
    },
  },
  methods: {
    mobileActive() {
      this.mobileClick = true
      // this.$emit("mobileActive",this.mobileClick);
      document.body.classList.add('mobile__active')
    },
    setLocale(locale) {
      if (this.$i18n.locale != locale) {
        this.$i18n.locale = locale
        localStorage.setItem('lang_locale', locale)
        this.$router.push({
          params: { lang: locale },
        })
      }
    },
  },
}
</script>

<style></style>
